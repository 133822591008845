import { TermsProps } from '../../@interface/terms'

export const dataPrivacy: TermsProps = {
  title: 'Política de Privacidade',
  update: '8 de agosto de 2023',
  about: {
    title: 'Política de Privacidade',
    introduction: `
    A CORA reconhece a importância das informações dos Usuários e as leva muito a sério.
    Por este motivo, elaboramos este documento (“Política de Privacidade”) para esclarecer quais informações são coletadas dos usuários
    de nosso site – <b>https://www.cora.com.br</b> – e respectivos serviços contratados por Sua Empresa, conforme descrito nos
    Termos e Condições Gerais de Uso da Conta CORA disponíveis em <a href="/termos-uso">Termos de Uso</a>, e de que forma esses dados são utilizados.
    <br/><br/>
    Você pode se recusar a autorizar o tratamento de seus dados
    pessoais. Destacamos, contudo, que, nesse caso, não será
    possível que você utilize os Serviços ou acesse as Aplicações na
    qualidade de representante de Sua Empresa. Caso não concorde com
    esta Política, por favor, não continue o seu procedimento de
    cadastro. Todavia, por favor, nos informe a sua discordância
    para que possamos esclarecê-las ou melhorá-las. Este documento é
    parte integrante dos nossos Termos de Uso, que contém uma visão
    geral dos nossos serviços.
    <br/><br/>
    Esta Política de Privacidade se aplica quando você utiliza os
    nossos Serviços que envolvem coleta de dados online e offline,
    incluindo os Dados Pessoais que coletados por meio de nossos
    vários serviços e canais, incluindo websites, aplicativos, redes
    sociais de terceiros, Serviço de Atendimento ao Consumidor,
    pontos de venda e eventos. Nossos usuários podem escolher, em
    determinados casos, os tipos de dados que coletamos, utilizamos
    e compartilhamos, conforme descrito nesta Política de
    Privacidade.
    <br/><br/>
    O aceite da nossa Política dar-se-á quando você, na condição de
    Usuário representante de Sua Empresa, clicar em “Li e concordo
    com os Termos de Uso e Política de Privacidade” e finalizar o
    seu cadastro através do nosso Aplicativo, e, mediante o momento
    em que você, de forma afirmativa, concordar com o uso de seus
    dados para cada uma das finalidades por nós aqui descritas. Isso
    indicará que você está ciente e em total acordo com a forma como
    utilizaremos as suas informações e os seus dados.
    <br/><br/>
    Este documento foi elaborado de forma simples e acessível,
    justamente para que você possa ler e entender a forma como
    utilizamos os seus dados para lhe oferecer uma experiência
    segura e confortável no uso dos serviços que oferecemos a você.
    Por isso, é necessário que você leia com atenção os termos aqui
    contidos antes de tomar a decisão de começar a usar os Serviços
    oferecidos pela CORA e seus respectivos parceiros.
    <br/><br/>
    A presente Política está dividida da seguinte forma para facilitar a sua compreensão:
    `,
  },
  section: [
    {
      list: [
        {
          head: 'Resumo da Política de Privacidade',
          items: [
            {
              item: `As disposições desta Política de Privacidade, em sua totalidade, são extremamente importantes para a nossa relação com você. Para facilitar a sua leitura, apresentamos no quadro abaixo os principais pontos aos quais você deve se atentar. De toda forma, o quadro resumo não substitui o texto deste documento e é essencial que você leia o documento na íntegra.
              <br /><br />
              <div class="table-privacy">
                <table>
                  <thead>
                    <th><b>Dados Pessoais</b></th>
                    <th><b>Finalidade</b></th>
                    <th><b>Base Legal</b></th>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Dados fornecidos por você (veja os itens 2.1 e 3.1, "i" abaixo)</td>
                      <td>
                        <ul>
                          <li>confirmação de sua identidade;</li>
                          <li>prestação dos Serviços contratados por Sua Empresa;</li>
                          <li>comunicação (via mensagens push, SMS, e-mail etc) inclusive para fins publicitários;</li>
                          <li>cruzamento e/ou enriquecimento com outros dados;</li>
                          <li>criação de perfis demoográficos, de consumo e socioeconômicos;</li>
                          <li>levantamento de informações estatísticas e de mercado;</li>
                          <li>melhoria dos produtos serviços e algoritmos da CORA e/ou de seus parceiros comerciais;</li>
                          <li>preparação de relatórios, métricas e outras soluções e software de inteligência de negócios voltadas à CORA ou a seus parceiros comerciais;</li>
                          <li>criação de novos serviços, produtos e funcionalidades;</li>
                          <li>direcionamento de anúncios aos titulares dos Dados e a terceiros;</li>
                          <li>identificação e apuração de suspeita de fraudes, violação de direitos de terceiros ou outros ilícitos praticados por usuários, investigação por terceiros prejudicados e/ou por autoridades competentes;</li>
                          <li>inclusão dos Dados entre os ativos da CORA para todos e quaisquer fins comerciais, inclusive para fins de reestruturação societária, como fusão, cisão ou incorporação ou qualquer outra forma de alienação de ativos; e</li>
                          <li>identificação e prevenção de eventuais ameaças de segurança.</li>
                        </ul>
                      </td>
                      <td>Consentimento<br/><br/>Execução de contrato a pedido do titular<br/><br/>Cumprimento de obrigação legal ou regulatória<br/><br/>Legítimo interesse do controlador</td>
                    </tr>
                    <tr>
                      <td>Dados coletados automaticamente (veja os itens 2.1 e 3.1, "ii" abaixo)</td>
                      <td>
                        <ul>
                          <li>análise comportamental/transacional para identificação e prevenção de situações de fraude e ameaças de segurança;</li>
                          <li>personalização e aprimoração dos Serviços com base na sua forma de utilização;</li>
                          <li>Analytics - reconhecimento de perfis e hábitos de consumo coletivos dos usuários dos Serviços a fim de gerar dados estatísticos que possibilitem melhor compreensão de como se dão as interações dos usuários com as Aplicações.</li>
                        </ul>
                      </td>
                      <td>Consentimento<br/><br/>Execução de contrato a pedido do titular<br/><br/>Cumprimento de obrigação legal ou regulatória<br/><br/>Legítimo interesse do controlador</td>
                    </tr>
                  </tbody>
                </table>
              <div/>`,
            },
          ],
        },
        {
          head: 'Considerações Iniciais',
          items: [
            {
              item: `Quais são as fontes de dados pessoais?
              <br />
              A quantidade e o tipo de informações coletadas pela CORA varia conforme o uso que você faz dos nossos serviços. Coletamos diferentes dados caso você esteja visitando nosso site, utilizando nossos serviços ou utilizando os serviços de nossos parceiros. Para tornar mais acessível essas definições e diferenças, segmentamos em quatro partes a coleta dos dados dos usuários, para que você possa compreender o que se aplica especificamente à você.
              <br />
              Esta Política se aplica aos Dados Pessoais que coletamos de/ou sobre você por meio das seguintes fontes:
              <br />
              <br />
              <b>Sites CORA</b>:
              <br />
              Sites voltados para o consumidor e operados pela ou para a CORA incluindo sites
              que nós operamos sob nossos próprios domínios/URLs, landing pages e mini sites
              que mantemos em redes sociais de terceiros, como o Facebook (sites).
              <br />
              <br />
              <b>Aplicativo móvel CORA</b>:
              <br />
              Aplicativo móvel voltado para o nosso cliente e operado pela CORA, como
              aplicativos para smartphones.
              <br />
              <br />
              <b>Interações com anúncios</b>:
              <br />
              Interações com nossos anúncios (por exemplo, se você interage com um ou mais de
              nossos anúncios em um site de um terceiro, nós podemos receber informações sobre
              aquela interação).
              <br />
              <br />
              <b>Dados criados pela CORA</b>:
              <br />
              Durante nossas interações com Você, poderemos criar
              Dados Pessoais sobre Você (por exemplo, mas não limitado a, registros de suas
              interações com os nossos conteúdos e e-mails).
              <br />
              <br />
              Cabe ainda mencionar que armazenamos informações a respeito de todos os contatos
              já realizados com nossos clientes. Tais informações incluem conteúdos baixados a
              partir de nossas páginas e interações via e-mail, como abertura e cliques.
              <br />
              Ao clicar nos botões de compartilhamento de conteúdo nas redes sociais
              disponíveis em nossas páginas, o usuário estará publicando o conteúdo por meio
              de seu perfil na rede selecionada. A CORA não tem acesso ao login e senha dos
              usuários nessas redes, nem publicará conteúdo em nome do usuário sem que ele
              realize esta ação.
              <br />
              Vale ressaltar que todos os dados que coletamos são utilizados para a prestação
              de nossos serviços. Por isso, todos os dados e informações sobre você são
              tratados como confidenciais, e somente os usaremos para os fins aqui descritos e
              autorizados por Você, principalmente para que você possa utilizar nossos
              serviços de forma plena, visando sempre melhorar a sua experiência como usuário.`,
            },
            {
              item: `Quais tipos de dados a CORA coleta?
              <br />
              <b> Visitantes do site </b>:
              <br />
              caso você apenas acesse o nosso site, coletaremos seus dados de navegação. No
              caso, quando você visita o site da Cora, é inserido um “cookie” (identificador
              que transferimos para o seu navegador ou dispositivo que nos permite reconhecer
              seu navegador ou dispositivo e nos informa como e quando as páginas e recursos
              em nosso site são visitados e quantas pessoas as acessam) no seu navegador por
              meio do software Google Analytics, para identificar quantas vezes você retorna
              ao nosso endereço.
              <br />
              <br />
              Além do Google Analytics, utilizamos algumas outras ferramentas a fim de
              otimizar a qualidade do serviço que oferecemos aos nossos clientes, de modo a
              registrar as interações do usuário com a nossa página e encaminhar para a
              plataforma do Linkedin com o intuito de otimizar as campanhas e permitir a
              análise de dados da performance delas, bem como possibilitar o “re-targeting”
              (anúncio personalizado para a pessoa que visitou o site com o LinkedIn
              insights).
              <br />
              <br />
              Quais sejam:
              <br />
              HotJar – https://www.hotjar.com/;
              <br />
              Linkedin Insights;
              <br />
              Google Tag; Facebook Pixel;
              <br />
              e HubSpot.
              <br />
              <br />
              Também usamos Cookies e dados de navegação, como URLs (Uniform Resource
              Locators) para coletar informações sobre a data e a hora da sua visita e as
              soluções e informações para as quais você pesquisou e visualizou. Podemos usar
              cookies de sessão (que expiram depois de fechar o navegador) e cookies
              persistentes (que permanecem no seu computador até que você os exclua) para
              fornecer uma experiência mais pessoal e interativa em nosso site. Os Cookies
              Persistentes podem ser removidos seguindo as instruções do arquivo de ajuda do
              seu navegador da Internet. Você pode optar por limitar, recusar ou desabilitar
              os Cookies através das configurações do seu navegador. Contudo, ao optar por uma
              dessas opções, algumas áreas do nosso site podem não funcionar corretamente, o
              que poderá impedir que você se beneficie de alguns de nossos recursos.
              <br />
              <br />
              <b>Usuários dos serviços Cora</b>:
              <br />
              Se você for usuário dos nossos serviços, coletamos as seguintes categorias e
              tipos de dados: dados de pessoa física (nome, CPF, e-mail e telefone, fotos de documento pessoal (ex:cnh, rg, carteira de trabalho e dentre outros documentos) e selfie), dados
              locacionais (país, estado e cidade), dados da pessoa jurídica (Razão Social,
              CNPJ, Quadro Societário e o segmento da sua empresa).
              `,
            },
            {
              item: `Quem é responsável pelo processamento dos seus Dados?
              <br />
              Os responsáveis pelo tratamento de seus Dados são a CORA PAGAMENTOS LTDA.,
              (“CORA”) inscrita no CNPJ sob o n. 34.052.649/0001-78 e a Cora Sociedade de Crédito, Financiamento e Investimento S.A., inscrita no CNPJ sob o n. 37.880.206/0001-63, ambas com
              sede na Rua Frei Caneca, 1246, 3º andar, sala 31, - CEP 01307-002, no Município de
              São Paulo, Estado de São Paulo.
              <br />
              Contato do encarregado de Dados Pessoais:
              <a href="mailto:privacidade@cora.com.br">privacidade@cora.com.br</a>.`,
            },
          ],
        },
        {
          head: 'Coleta de Dados',
          items: [
            {
              item: `Alguns Dados poderão ser coletados quando você nos informar diretamente ou
              através de suas interações com os Serviços e Aplicações, e que serão tratados em conformidade com a legislação aplicável, bem como com a presente Política de
              Privacidade.
              <br />
              <b>a)</b> Dados fornecidos por você: quaisquer informações submetidas por você, por
              exemplo, quando preenche o cadastro ou contrata algum dos Serviços, na qualidade
              de Usuário representante de Sua Empresa, e dizem respeito a informações como
              nome, selfie, número do Cadastro de Pessoa Física do Ministério da Economia (“CPF/ME”),
              número de passaporte, endereços de e-mail ou telefone, números de contas de
              pagamento ou conta corrente, tanto suas quanto de terceiros, movimentação
              financeira via Aplicações e a natureza e tipo de suas despesas, dentre outros.
              <br />
              <b>b)</b> Dados coletados automaticamente: são os Dados obtidos automaticamente em razão
              de suas interações com a CORA, com os Serviços ou com as Aplicações, como, por
              exemplo, endereços de IP (Internet Protocol, conjunto de números que identifica
              o computador dos usuários), hora e data de acesso, cookies e tecnologias
              similares, como pixels e tags, ações efetuadas por você nos Serviços, seções das
              Aplicações acessadas por Você, informações de data e horário de suas ações e
              acessos, session ID, dispositivo móvel utilizado, se aplicável, geolocalização,
              câmera, galeria de fotos, informações sobre a sua tela e resolução, tempo
              despendido, perfil e comportamento de compra, volume e número de transações em
              estabelecimentos e por meio das Aplicações, dentre outras informações.
              <br />
              Todos esses Dados serão usados para possibilitar o seu acesso às Aplicações e
              efetiva contratação dos Serviços, conforme contratação específica realizada por
              Você, na qualidade de Usuário representante de Sua Empresa, e nos moldes
              especificados nos Termos de Uso, para solucionar eventuais erros ou problemas
              futuros semelhantes que possam ocorrer.
              `,
            },
          ],
        },
        {
          head: 'Finalidade do Tratamento de Dados',
          items: [
            {
              item: `Por que tratamos seus Dados?<br/>
              Tratamos os Dados com o objetivo principal de possibilitar a sua utilização dos Serviços que Você, na qualidade de representante de Sua Empresa, contratar com a CORA, da forma mais proveitosa possível.`,
            },
            {
              item: `Qual é a legitimidade do tratamento?<br/>
              A base legal que legitima o tratamento de seus Dados é a execução da relação jurídica entre as partes decorrente de sua utilização dos Serviços prestados pela CORA ou seus parceiros, cumprimento de obrigação legal, as autorizações que você nos conceder nos termos desta Política de Privacidade e, ainda, o legítimo interesse da CORA.`,
              child: [
                {
                  item: `Os Dados Pessoais Sensíveis serão tratados unicamente para as finalidades expressamente autorizadas por Lei sem o fornecimento do consentimento.`,
                  child: [
                    {
                      item: `Porque coletamos a sua Selfie?<br/>
                      Sua selfie apenas será coletada e utilizada para as finalidades de (i) registro, autorização de um novo dispositivo; (ii) mudança de e-mail;  (iii) mudança da senha do cartão; e (iv) verificar e autorizar transações consideradas suspeitas.`,
                    },
                    {
                      item: `Como a coleta da selfie é realizada?<br/>
                      Sua selfie é  coletada através de um SDK do nosso fornecedor autorizado, que é enviado ao sistema junto com a sua identificação. Tal fornecedor compara seus dados com a selfie tirada durante o processo de registro e nos diz se você é quem diz ser.`,
                    },
                    {
                      item: `Segurança<br/>
                      Sua selfie será tratada e enviada exclusivamente através de um pedido https autenticado e criptografado ao nosso fornecedor autorizado.`,
                    },
                    {
                      item: `Tempo de retenção da Selfie<br/>
                      Uma vez que utilizamos a selfie, esta será mantida enquanto você for um cliente da Cora e/ou para fins de cumprimento de quaisquer obrigações legais, contratuais, ou requisição de autoridades competentes.`,
                    },
                  ],
                },
              ],
            },
            {
              item: `Para que os Dados poderão ser utilizados?<br/>
              Os Dados (inclusive Dados Pessoais e Dados Pessoais Sensíveis) poderão ser utilizados (“Usos Autorizados”) para os fins de:`,
              child: [
                {
                  item: `Prestação dos Serviços contratados por Você na qualidade de Usuário representante de Sua Empresa, inclusive para iniciar, processar, enviar ou receber um pagamento, creditar valores em uma conta, autenticar e permitir o acesso à Conta CORA;`,
                },
                {
                  item: `Administração da Conta CORA e dos Serviços contratados por você na qualidade de Usuário representante de Sua Empresa;`,
                },
                {
                  item: `Contato por meio telefônico, notificações (push) e mensagens de todo tipo inclusive via aplicativos de mensagens, SMS e e-mail, para oferecimento de publicidade e para comunicar novidades e atualizações;`,
                },
                {
                  item: `Cruzamento e/ou enriquecimento com outros dados que já estejam em posse da CORA, ou que venham a ser obtidos e incluídos em nossos sistemas;`,
                },
                {
                  item: `Criação de perfis demográficos, de consumo e socioeconômicos de pessoas físicas e jurídicas;`,
                },
                {
                  item: `Levantamento de informações estatísticas e de mercado;`,
                },
                {
                  item: `Melhoria dos produtos, serviços e algoritmos da CORA e/ou de seus parceiros comerciais;`,
                },
                {
                  item: `Preparação de relatórios, métricas e outras soluções e software de inteligência de negócios voltadas à CORA ou a seus parceiros comerciais;`,
                },
                {
                  item: `Criação de novos serviços, produtos e funcionalidades, assim como para o direcionamento de anúncios aos titulares dos Dados e a terceiros;`,
                },
                {
                  item: `Apuração de suspeita de fraudes, violação de direitos de terceiros ou outros ilícitos praticados por usuários, investigação por terceiros prejudicados e/ou por autoridades competentes;`,
                },
                {
                  item: `Inclusão dos Dados entre os ativos da CORA para todos e quaisquer fins comerciais, inclusive para fins de reestruturação societária, como fusão, cisão ou incorporação ou qualquer outra forma de alienação de ativos;`,
                },
                {
                  item: `Análise comportamental/ transacional para identificação e prevenção de situações de fraude e ameaças de segurança;`,
                },
                {
                  item: `Personalização e aprimoramento dos Serviços com base na sua forma de utilização;`,
                },
                {
                  item: `Analytics – reconhecimento de perfis e hábitos de consumo coletivos dos usuários dos Serviços, a fim de gerar dados estatísticos que possibilitem melhor compreensão de como se dão as interações dos usuários com as Aplicações.`,
                },
              ],
            },
            {
              item: `O eventual uso dos Dados para finalidades não descritas no item 3.3 acima será feito mediante notificação prévia a você, sempre que possível e razoável.`,
            },
          ],
        },
        {
          head: 'Compartilhamento e Transferência de Dados',
          items: [
            {
              item: `Algumas vezes, precisamos compartilhar os seus Dados Pessoais com terceiros que prestam serviços em nosso nome. É o caso, por exemplo, dos serviços que contratamos para a hospedagem das nossas bases de dados ou para auditorias e verificação de identidade.
              <br/>
              Abaixo descrevemos algumas situações no contexto da Cora nas quais podemos compartilhar os seus Dados Pessoais:
              <br/>
              <b>Nossos fornecedores</b>: Contamos com a ajuda de fornecedores que podem tratar Dados Pessoais que coletamos. Sempre buscamos avaliar cuidadosamente nossos fornecedores e firmar com eles obrigações contratuais de proteção de Dados Pessoais e segurança da informação, com o objetivo de minimizar riscos para os Titulares dos Dados, tais como, mas não se limitando:
              (i) O fornecedor só pode dedicar o tratamento dos Dados Pessoais compartilhados pela Cora, conforme estritamente necessário para atingir cumprir o objeto do contrato, reconhecendo a vedação ao uso dos Dados Pessoais para qualquer finalidade ou propósito alheios aos indicados no contrato;
              <br/>
              (ii) O fornecedor deve manter o registro de todas as atividades realizadas em seus sistemas e ambientes, principalmente das operações de tratamento de dados pessoais, além de implementar as medidas técnicas e organizacionais necessárias para protegê-los contra a destruição acidental ou ilícita, a perda, a alteração, a comunicação, a difusão e o acesso não autorizado;
              <br/>
              (iii) O fornecedor deve garantir por si, seus sócios, prepostos, funcionários e suboperadores em geral, o sigilo absoluto, a privacidade, a confidencialidade e a segurança dos Dados Pessoais a que tiver acesso durante a realização da atividade, não sendo permitido o uso ou compartilhamento de tais Dados Pessoais;
              <br/>
              (iv) O fornecedor deve responder, e ser responsável perante a Cora, pelas ações e omissões de seus sócios, prepostos, empregados, suboperadores e funcionários. O fornecedor deve exigir que todos aqueles que tenham acesso aos Dados Pessoais relacionados ao contrato de prestação de serviços concordem por escrito em proteger a privacidade, confidencialidade e segurança de tais Dados Pessoais;
              <br/>
              (v) Caso o fornecedor terceirize as suas operações de processamento de Dados Pessoais, ou tenha que compartilhar os Dados Pessoais para cumprir o objeto do contrato, esse se dará por meio de contrato firmado por escrito com o suboperador que imponha as mesmas obrigações ao suboperador impostas ao fornecedor no Contrato. Caso o suboperador não cumpra com suas obrigações de proteção de dados nos termos do referido contrato firmado por escrito, o fornecedor será responsável, perante a Cora, o titular e demais envolvidos, incluindo autoridades administrativas e judiciais, pelo cumprimento das obrigações do suboperador;
              <br/>
              (vi)  Adotar todas as medidas necessárias para atender ou cooperar com o atendimento de eventuais solicitações dos titulares direcionadas ao exercício dos direitos previstos no art. 17 e ss. da LGPD.
              <br/>
              Dependendo da atividade por eles realizada, como em casos de acesso às informações de clientes, por exemplo, podemos solicitar os Dados Pessoais dos fornecedores para verificação semelhante àquela aplicada para funcionários da Cora a fim de comprovar a sua idoneidade, prezando sempre por tratar o mínimo de Dados Pessoais possível. Entre esses fornecedores, estão, por exemplo, empresas com bases de dados públicos que contratamos para nos auxiliarem em análises de crédito para quando você abrir uma conta ou contratar uma operação de crédito.
              <br/>
              <b>Analytics</b>: Os dados armazenados pela Cora podem vir a ser utilizados para fins de estatísticas (analytics), para que a Cora possa compreender quem são as pessoas que visitam nosso site e que são consumidoras dos nossos Produtos e Serviços. Estes dados são pseudonimizáveis e não buscam identificar ou tornar identificáveis os Titulares dos Dados Pessoais, mas tão somente compreender melhor como é o seu acesso na Plataforma Digital da Cora, a fim de melhorar a prestação de serviços e customizar produtos mais direcionados aos seus interesses.
              <br/>
              <br/>
              <b>Autoridades Públicas</b>: Temos que cumprir a lei. Assim, se uma autoridade com competência legal, como o BACEN, CVM, Procon, ANPD, exigir que a Cora compartilhe certos Dados Pessoais para, por exemplo, atendimento de necessidades regulatórias, necessitaremos compartilhar essas informações. Somos contra qualquer abuso de autoridade e, caso a Cora entenda que determinada ordem é abusiva, vamos sempre privilegiar sua privacidade.
              <br/>
              <br/>
              <b>Proteção de direitos</b>: Além disso, reservamo-nos o direito de compartilhar quaisquer Dados Pessoais que acreditarmos serem necessários para cumprir uma obrigação legal, aplicar nossos Termos de Uso ou, ainda, proteger os direitos da Cora, nossos colaboradores e clientes.
              <br/>
              <br/>
              <b>Grupo Econômico</b>: Caso você, por exemplo, se interesse ou possa se interessar pelos nossos serviços de outras empresas de nosso Grupo Econômico, poderemos compartilhar seus Dados Pessoais com as empresas do nosso grupo econômico ou outras empresas ou pessoas confiáveis para tratar tais informações para essa finalidade.
              <br/>
              <br/>
              <b>Parceiros Comerciais</b>: Poderemos compartilhar seus Dados Pessoais, como informações cadastrais ou dados de navegação ou uso da Plataforma, com nossos parceiros comerciais ou com outras empresas ou pessoas confiáveis para tratar tais informações para fins de prestação de serviços que te interessem ou que venham a te interessar.
              <br/>
              <br/>
              <b>Caso tenha qualquer dúvida em relação a quais são essas empresas ou nossos fornecedores, fique à vontade para entrar em contato conosco através dos canais informados nesta Política.</b>
              `,
            },
            {
              item: `Ao criar uma conta na Cora você consente que a Cora poderá, nos termos da resolução conjunta nº 6 de 2023 do Banco Central, compartilhar os seus dados pessoais de identificação, os dados da sua empresa, e os dados financeiros da sua empresa, caso haja qualquer indício de fraude praticado por você, ou caso a sua conta bancária tenha sido destinatária de uma transação financeira que tem indício de fraude.`,
            },
          ],
        },
        {
          head: 'Transferência Internacional de Dados',
          items: [
            {
              item: `A Cora coleta e transfere dados pessoais coletados no Brasil para países localizados fora da América do Sul. Essa transferência ocorre para empresas parceiras que atuam no tratamento e processamento de dados pessoais, e que demonstram estar em processo de conformidade ou em conformidade com a GDPR e com as leis setoriais brasileiras de proteção de dados.`,
            },
            {
              item: `A Cora possui sua sede no Brasil e os dados que coletamos são regidos pela lei brasileira. Ao acessar ou usar os nossos Serviços ou fornecer dados pessoais para nós, você concorda com o processamento e a transferência de tais dados para o Brasil e para outros países. Nesse último caso, haverá uma transferência internacional de Dados, da qual você declara estar ciente e com a qual você declara concordar.`,
            },
          ],
        },
        {
          head: 'Por quanto tempo a Cora irá armazenar os dados?',
          items: [
            {
              item: `Nós manteremos seus dados pessoais somente pelo tempo que for necessário para cumprir com as finalidades para as quais os coletamos, inclusive para fins de cumprimento de quaisquer obrigações legais, contratuais, ou requisição de autoridades competentes.`,
            },
            {
              item: `Se você utiliza os nossos serviços, as informações da sua conta serão mantidas enquanto você mantiver essa conta ativa.`,
            },
            {
              item: `Os Dados Pessoais serão excluídos dos sistemas da CORA mediante:
              <br/>
              <ol>
                <li>
                  <b>a)</b>
                  requisição dos titulares dos Dados Pessoais; ou
                </li>
                <li>
                  <b>b)</b>
                  quando estes não forem mais necessários para os Usos Permitidos, salvo se
                  houver qualquer outra razão, base legal, regulatória ou contratual, para a
                  sua manutenção, como:
                  <ol>
                    <li>
                      <b>b.i)</b> eventual obrigação legal ou regulatória de retenção de Dados
                      Pessoais;
                    </li>
                    <li>
                      <b>b.ii)</b>
                      necessidade de preservação destes para resguardo de direitos;
                    </li>
                    <li><b>b.iii)</b> interesses legítimos da CORA; ou</li>
                    <li><b>b.iv)</b> em razão de ordem judicial.</li>
                  </ol>
                </li>
              </ol>
              `,
              child: [
                {
                  item: `Caso você solicite a exclusão de seus Dados, por favor, saiba que o seu acesso e utilização dos Serviços e Aplicações será permanentemente interrompido, sendo considerada rescindida a sua relação com a CORA, conforme disposto nos Termos de Uso. Sem prejuízo, caso Sua Empresa possua outros Usuários cadastrados, a prestação dos Serviços poderá continuar normalmente. Caso você deseje utilizar novamente as Aplicações e/ou os Serviços, na qualidade de Usuário representante de Sua Empresa, seus Dados devem ser novamente fornecidos, mediante seu cadastro como Usuário.`,
                },
              ],
            },
            {
              item: `Dessa forma, FICA RESERVADO À CORA O DIREITO DE ACESSAR, LER, PRESERVAR E DIVULGAR QUAISQUER DADOS NECESSÁRIOS PARA:
              <br/>
              CUMPRIR UMA OBRIGAÇÃO LEGAL/REGULATÓRIA OU UMA ORDEM JUDICIAL EMANADA DE AUTORIDADES COMPETENTES; FAZER CUMPRIR E APLICAR OS TERMOS DE USO E OUTROS ACORDOS FIRMADOS COM VOCÊ; PROTEGER OS DIREITOS, PROPRIEDADE E SEGURANÇA DA CORA E/OU DE SEUS PARCEIROS, BEM COMO DE SEUS RESPECTIVOS REPRESENTANTES, PRESTADORES DE SERVIÇOS, COLABORADORES E USUÁRIOS.`,
            },
          ],
        },
        {
          head: 'Quais são os direitos dos titulares de dados?',
          items: [
            {
              item: `Você sempre poderá optar em não divulgar seus dados para nós, mas tenha em mente que alguns desses dados podem ser necessários para usufruir dos serviços que oferecemos a você. Independente disso, você sempre possuirá direitos relativos à privacidade e a proteção dos seus dados pessoais, e nós além de nos preocuparmos com a segurança desses dados, também nos preocupamos que você tenha acesso e conhecimento de todos os seus direitos relativos a dados pessoais.`,
            },
            {
              item: `Os titulares de Dados Pessoais podem exercer seus direitos de confirmação da existência de tratamento, acesso, retificação, anonimização, bloqueio ou eliminação de Dados desnecessários, excessivos ou tratados em desconformidade com a Lei, exclusão, nos casos em que for possível a portabilidade dos Dados, informação das entidades públicas e privadas com as quais foi realizado o uso compartilhado de Dados, bem como solicitar limitação ou se opor ao tratamento de Dados (caso em que a utilização dos Serviços será limitada ou impossibilitada) e pleitear a revisão de decisões individuais automatizadas, enviando uma solicitação para o endereço de contato acima.`,
            },
            {
              item: `Os titulares de Dados Pessoais podem, em qualquer caso, apresentar uma reclamação junto à Autoridade Nacional de Proteção de Dados, especialmente quando não obtiverem satisfação no exercício de seus direitos.`,
            },
            {
              item: `Cada parte se compromete a manter e tratar como confidencial e não revelar a terceiros qualquer informação relacionada aos Serviços ou Aplicações CORA (“Informação Confidencial”), inclusive Dados de usuários, segredos de negócios e outros.`,
            },
          ],
        },
        {
          head: 'Outras Informações',
          items: [
            {
              item: `A obrigação de confidencialidade aqui prevista permanecerá vigente enquanto perdurar o caráter de confidencialidade das informações recebidas`,
            },
            {
              item: `As Partes se comprometem a seguir regras de privacidade, proteção de Dados, confidencialidade ou requisitos de segurança de informações, em conformidade com as melhores práticas e a legislação aplicável, com o objetivo de garantir a confidencialidade e o uso adequado dos Dados, bem como a sua não divulgação, exceto na forma autorizada pelos Termos de Uso, pela presente Política de Privacidade ou pela legislação pertinente.`,
            },
            {
              item: `Em nenhuma circunstância a CORA se responsabilizará por qualquer delito, negligência, descumprimento contratual ou outra hipótese de ilícito civil ou penal que venham a ser causados por Você, incluindo o uso não autorizado de seus Dados, sendo sua total responsabilidade arcar com todos e quaisquer danos, monetários ou de outra natureza, decorrentes de sua atuação indireta, direta ou incidental, cabendo à CORA o direito de regresso por tais situações.`,
            },
            {
              item: `A CORA preza muito pela confiança depositada por Você e busca sempre manter medidas de segurança técnicas, físicas e administrativas para fornecer proteção razoável para os Dados Pessoais contra perda, mau uso, acesso não autorizado, divulgação e alteração (como firewalls, controles de acesso e criptografia de Dados). Ainda que a CORA mantenha essas medidas visando proteger os Serviços e Aplicações, você deve manter em segurança sua senha e demais informações sobre a Conta CORA de Sua Empresa, bem como se utilizar apenas de ambiente e equipamentos seguros para realizar as conexões necessárias.`,
            },
            {
              item: `Dependendo do tipo de Serviço que você, na qualidade de representante de Sua Empresa, contratar, é possível que esse Serviço seja prestado diretamente por terceiros. Nesse caso, você será redirecionado a uma página externa e deixará o ambiente CORA, devendo acessar, ler e aceitar os Termos e Condições e a Política de Privacidade desse terceiro. A CORA não possui qualquer ingerência no estabelecimento desses termos e políticas. Caso você decida utilizar os serviços e aplicações de terceiros, você o fará por sua conta e risco.`,
            },
            {
              item: `Essa Política de Privacidade é regida pelas Leis da República Federativa do Brasil.`,
            },
          ],
        },
        {
          head: 'Mudanças na Política de Privacidade',
          items: [
            {
              item: `Como estamos sempre buscando melhorar nossos serviços, a CORA poderá ajustar esta Política de Privacidade periodicamente. Desta forma, recomendamos visitar periodicamente esta página para que você tenha conhecimento sobre as modificações. Caso sejam feitas alterações relevantes que ensejem em um novo consentimento seu, nós iremos te notificar com antecedência, de forma que, caso não concorde com os novos termos, você poderá optar por interromper a utilização dos Serviços e das Aplicações.`,
            },
            {
              item: `Última Atualização em 09/09/2022`,
            },
          ],
        },
      ],
    },
  ],
}
